<template>
  <div class="header-container padding-1">
    <div class="logo">
      <!-- https://nuxt.com/modules/icons -->
      <Icon name="LogoColor" />
    </div>
    <div class="flex gap-1">
      <Icon name="dashicons:unlock" />
      <p>{{ _vh_legged_in_email }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useClickOutside } from '@/composables/clickOutside'
import { useAuthStore } from '~/store/auth' // import the auth store we just created

const { logOutUser } = useAuthStore() // use loginUser action from  auth store

// This must be used from the cookie
const _vh_legged_in_email = useCookie('_vh_legged_in_email') // get token from cookies

const router = useRouter()
const runtimeSettings = useRuntimeConfig()
const appVersionIndividual =
  runtimeSettings.public.appVersionIndividual === 'true'
    ? 'Individual version'
    : 'Enterprise version'

// const showProfileList = ref(false);
const loggedInHover = ref(false)
const isProfileClicked = ref(false)

const profileSwitch = ref(null)
const profileLoggedIn = ref(null)

useClickOutside(profileSwitch, () => {
  isProfileClicked.value = false
})

useClickOutside(profileLoggedIn, () => {
  loggedInHover.value = false
})

const logoutAction = () => {
  logOutUser()
  router.push('/login')
}

onMounted(() => {
  // Set current client profile id on page load
  // setClientProfile({ id: Number(id.value) });
})
</script>

<style scoped>
.header-container {
  background-color: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  text-align: left;
  margin-left: 20%;
  z-index: 10;
  position: sticky;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
}

.profile-loggedin {
  float: right;
  margin-top: -5px;
  justify-self: flex-end;
}

.profile-loggedin:hover {
  cursor: pointer;
}

.profile-loggedin .flex {
  align-items: center;
}

.profile-loggedin img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-loggedin .profile-loggedin-list {
  display: none;
  position: absolute;
  top: 58px;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  z-index: 10;
  padding: 1em;
  margin: 0;
  list-style: none;
  min-width: 150px;
}

.profile-loggedin.show .profile-loggedin-list {
  display: block;
}

.profile-loggedin.show .profile-loggedin-list li {
  border-bottom: 1px solid #e5e7eb;
}

.profile-loggedin.show .profile-loggedin-list li:last-child {
  border-bottom: none;
}

.profile-loggedin.show .profile-loggedin-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.profile-loggedin-list a p {
  transition: all 0.3s ease;
}

.profile-loggedin-list a:hover p {
  color: var(--primary-color);
  cursor: pointer;
}

.router-link-active p {
  color: var(--primary-color);
}

.logo svg {
  height: 35px;
  width: 120px;
}

@media (max-width: 1025px) {
  .header-container {
    margin: 0 auto;
    z-index: 10;
    position: sticky;
    top: 0;
    right: 0;
  }
}
</style>
