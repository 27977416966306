<template>
  <div class="fixed-mobile-icons">
    <div class="flex">
      <div class="text-align-center">
        <a :href="dashboardLink">
          <Icon name="dashicons:text" />
          <p>Scanner</p>
        </a>
      </div>

      <div>
        <div class="text-align-center">
          <Icon
            @click="emitAudioEnabled(false)"
            v-if="audioEnabled"
            name="dashicons:controls-volumeon"
          />
          <Icon
            @click="emitAudioEnabled(true)"
            v-if="!audioEnabled"
            name="dashicons:controls-volumeoff"
          />
        </div>
        <p v-if="audioEnabled">Sound</p>
        <p v-if="!audioEnabled">Muted</p>
      </div>

      <div class="text-align-center">
        <a href="/scanner/support">
          <Icon name="dashicons:editor-help" />
          <p>Support</p>
        </a>
      </div>

      <div class="text-align-center">
        <Icon name="dashicons:unlock" @click="logoutAction" />
        <p>Logout</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia' // import storeToRefs helper hook from pinia
import { useAuthStore } from '~/store/auth' // import the auth store we just created

const { authenticated, role } = storeToRefs(useAuthStore()) // make authenticated state reactive with storeToRefs

// Define emits
const emit = defineEmits(['audioEnabled'])

const { logOutUser } = useAuthStore() // use loginUser action from  auth store

const router = useRouter()
const route = useRoute()
const { isMobile } = useIsMobile()
const audioEnabled = ref(false)
// Must be selected from route
const dashboardLink = ref('')

const { currentRoute } = useRouter()
// getRoute used for setting the active class on the sidebar
const getRoute = ref(currentRoute.value.name)

// Listen for the emitted event
const emitAudioEnabled = (value: boolean) => {
  audioEnabled.value = value
  emit('audioEnabled', audioEnabled.value)
}

const logoutAction = () => {
  logOutUser()
  router.push('/login')
}

onMounted(async () => {
  dashboardLink.value = `/scanner/${route.params.id}`
})

// Watch router
// When we click on a client profile, we want to update the links in the sidebar
watch(
  () => currentRoute.value,
  async (value) => {
    getRoute.value = value.name
    dashboardLink.value = `/scanner/${route.params.id}`
  },
  { deep: true, immediate: true }
)
</script>

<style scoped>
.fixed-mobile-icons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  background-color: #111827;
  z-index: 999;
  display: flex;
  justify-content: space-around;
  align-items: self-end;
  box-shadow: 0px 0px 3px 0px #999;
}

.fixed-mobile-icons .flex {
  gap: 4em;
}

.fixed-mobile-icons .icon {
  font-size: 2rem;
  color: #fff;
  text-align: center;
}

.fixed-mobile-icons p {
  font-size: 0.6rem;
  color: #fff;
  text-align: center;
}
</style>
