<template>
    <div>
        <ScannerFixedControlsMobile @audioEnabled="handleAudioEnabled" />
        <ScannerHeader v-if="isMobile" />
        <!-- https://nuxt.com/docs/guide/directory-structure/layouts#default-layout -->
        <!-- In a layout file, the content of the page will be displayed in the <slot /> component. -->
        <!-- ERROR WITH CHANGING LAYOUTS 
             https://vuejs.org/guide/built-ins/suspense.html
             https://github.com/nuxt/nuxt/issues/13309#issuecomment-1935405858
        -->
        <slot />
    </div>
</template>

<script setup lang="ts">

import { provide } from 'vue';


const { isMobile } = useIsMobile();
const audioEnabled = ref(false);

// Provide the audioEnabled value
provide('audioEnabled', audioEnabled);

// Handle the audioEnabled event
const handleAudioEnabled = (value: boolean) => {
  audioEnabled.value = value;
};


</script>